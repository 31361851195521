<template>
  <v-app>
    <app-title-header></app-title-header>
    <v-main>
      <v-container>
        <div v-if="message === ''">確認中...</div>
        <div v-else>
          <v-alert :type="alertType" outlined>{{ message }}</v-alert>
        </div>
      </v-container>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { getDeviceAlertDisable } from '@/api'
import AppTitleHeader from '@/components/app-title-header.vue'
import AppFooter from '@/components/app-footer.vue'

export default {
  name: 'DeviceAlertDisable',
  components: {
    AppTitleHeader,
    AppFooter
  },
  data() {
    return {
      message: '',
      alertType: ''
    }
  },
  async mounted() {
    if (this.$route.query.hash === undefined) {
      this.message = '無効なパラメータです'
      this.alertType = 'error'
      return
    }
    let res
    try {
      res = await getDeviceAlertDisable(this.$route.query.hash)
    } catch (e) {
      this.message = '無効なパラメータです'
      this.alertType = 'error'
      return
    }
    this.message = res.notification_disabled
      ? `${res.next_notification_time} まで浸水アラート通知を無効にしました。`
      : '浸水アラート通知の一定時間無効を解除しました。'
    this.alertType = 'success'
  }
}
</script>
